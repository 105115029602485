import { ref } from 'vue'
import { debounce } from 'lodash'
import apiClient from '@/services/axios'
import moment from 'moment'

const Base64Encode = (val) => {
    return btoa(val)
}

const FormatNumber = (val) => {
    if (val == null) return '0'
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}


// https://stackoverflow.com/a/61994179
const FormatCurrency = (number, decimals = 2, decimalSeparator = ',', thousandsSeparator = '.') => {
    // Returns the default format if the input is not valid
    if (number === null || number === undefined || number === '') {
        return `0${decimalSeparator}${'0'.repeat(decimals)}`
    }

    // Sanitize inputs and convert to space
    const sanitizedNumber = parseFloat(String(number).replace(/[^0-9.-]/g, ''))
    if (isNaN(sanitizedNumber)) throw new Error("The value given is not a valiable number.")

    // Number format with decimals
    const [integerPart, fractionalPart = ''] = sanitizedNumber.toFixed(decimals).split('.')

    // Add ribua separator
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)

    return `${formattedInteger}${decimals ? decimalSeparator + fractionalPart : ''}`
}

const DateFormat = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : null
}

const DateFormatHuman = (date) => {
    return date ? moment(date).format('DD MMMM YYYY') : null
}

const DatetimeFormatHuman = (date) => {
    return date ? moment(date).format('DD MMMM YYYY HH:mm:ss') : null
}

const DateFormatMonth = (date) => {
    return date ? moment(date).format('YYYY-MM') : null
}

const FormatPercentage = (value, decimals = 2) => {
    // Convert the value to a decimal number according to the desired amount
    const num = parseFloat(value)
    if (isNaN(num)) {
        throw new Error("The value given is not a valiable number.")
    }
    // Returns a value with the specified decimal
    return num.toFixed(decimals)
}

const createValidatorRule = (() => {
    const loading = ref(false) // Status loading untuk validasi

    // Fungsi utama validasi
    const validate = async (endpoint, value) => {
        loading.value = true
        try {
            const { data } = await apiClient.post(endpoint, { data: value })
            if (data.status === "success") {
                return Promise.resolve()
            } else {
                return Promise.reject(data.message)
            }
        } catch (error) {
            return Promise.reject(
                error.response?.data?.message || "Gagal memvalidasi.",
            )
        } finally {
            loading.value = false
        }
    }

    // Fungsi debounce untuk menunda validasi
    const debouncedValidate = debounce((endpoint, value, resolve, reject) => {
        validate(endpoint, value).then(resolve).catch(reject)
    }, 300)

    // Fungsi wrapper untuk validasi
    return (endpoint, value) => {
        return new Promise((resolve, reject) => {
            // Selalu gunakan fungsi debounce
            debouncedValidate(endpoint, value, resolve, reject)
        })
    }
})()

const isUrlPdf = (url) => {
    return url.toLowerCase().endsWith(".pdf")
} 

export {
    Base64Encode,
    FormatNumber,
    FormatCurrency,
    DateFormat,
    DateFormatHuman,
    DatetimeFormatHuman,
    DateFormatMonth,
    FormatPercentage,
    createValidatorRule,
    isUrlPdf,
}